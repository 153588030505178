const appConfig = {
  // apiPrefix: "https://api.cleocards.shop/api/v1/admin",
  // apiPrefix: "http://localhost:4000/api/v1/admin",
  apiPrefix: "https://api.cleocards.com/api/v1/admin",
  // apiPrefix: process.env.REACT_APP_API_ENDPOINT,
  authenticatedEntryPath: "/cards",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  locale: "en",
};

export default appConfig;
